import React from 'react';

import Icon from './Icon';

export default function BookmarkletIcon({ className = '', text = 'Newsletter' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M15.25 3C15.25 2.58579 15.5858 2.25 16 2.25H18C20.0711 2.25 21.75 3.92893 21.75 6V8C21.75 8.41421 21.4142 8.75 21 8.75C20.5858 8.75 20.25 8.41421 20.25 8V6C20.25 4.75736 19.2426 3.75 18 3.75H16C15.5858 3.75 15.25 3.41421 15.25 3Z" />
<path d="M21 15.25C21.4142 15.25 21.75 15.5858 21.75 16V18C21.75 20.0711 20.0711 21.75 18 21.75H16C15.5858 21.75 15.25 21.4142 15.25 21C15.25 20.5858 15.5858 20.25 16 20.25H18C19.2426 20.25 20.25 19.2426 20.25 18V16C20.25 15.5858 20.5858 15.25 21 15.25Z" />
<path d="M3.75 16C3.75 15.5858 3.41421 15.25 3 15.25C2.58579 15.25 2.25 15.5858 2.25 16V18C2.25 20.0711 3.92893 21.75 6 21.75H8C8.41421 21.75 8.75 21.4142 8.75 21C8.75 20.5858 8.41421 20.25 8 20.25H6C4.75736 20.25 3.75 19.2426 3.75 18V16Z" />
<path d="M6 3.75C4.75736 3.75 3.75 4.75736 3.75 6V8C3.75 8.41421 3.41421 8.75 3 8.75C2.58579 8.75 2.25 8.41421 2.25 8V6C2.25 3.92893 3.92893 2.25 6 2.25H8C8.41421 2.25 8.75 2.58579 8.75 3C8.75 3.41421 8.41421 3.75 8 3.75H6Z" />
<path d="M12.0009 14.8869L9.72798 16.5153C9.34709 16.7881 8.84558 16.8251 8.42881 16.611C8.01204 16.3969 7.75001 15.9677 7.75 15.4991V9.5C7.75 8.25736 8.75736 7.25 10 7.25H14C15.2426 7.25 16.25 8.25736 16.25 9.5V15.4933C16.25 15.9616 15.9883 16.3906 15.5719 16.6048C15.1555 16.819 14.6544 16.7826 14.2734 16.5104L12.0009 14.8869ZM10 8.75C9.58579 8.75 9.25 9.08579 9.25 9.5V15.0125L11.2727 13.5634C11.7074 13.2519 12.2922 13.2515 12.7273 13.5624L14.75 15.0075V9.5C14.75 9.08579 14.4142 8.75 14 8.75H10Z" />
</svg>
  </Icon>;
}
