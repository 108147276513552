import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { showModal } from '../../../shared/foreground/actions/modals';
import copyTextOnWeb from '../../../shared/foreground/copyTextOnWeb';
import { globalState } from '../../../shared/foreground/models';
import background from '../../../shared/foreground/portalGates/toBackground/singleProcess';
import { saveNewDocument } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/create';
import { addFeed } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { queueJob } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/jobs';
import { createToast } from '../../../shared/foreground/toasts.platform';
import { DisplayTheme, JobType, ShortcutId } from '../../../shared/types';
import { BrowserType, getCurrentBrowser } from '../../../shared/utils/environment';
import getServerBaseUrl from '../../../shared/utils/getServerBaseUrl.platform';
import { isValidRssUrl } from '../../../shared/utils/isValidRssUrl';
import { useHotKeysPreventDefault } from '../hooks/hooks';
import { useShortcutsMap } from '../utils/shortcuts';
import useLocation from '../utils/useLocation';
import Button from './Button';
import { FileUploadContext } from './FileDropzone';
import ChevronDownIcon from './icons/20StrokeChevronDownSmall';
import NewsletterIconLightMode from './icons/24SolidNewsLetterIcon';
import NewsletterIconDarkMode from './icons/24SolidNewsLetterIconDarkMode';
import BookmarkletIcon from './icons/24StrokeBookmarkletIcon';
import FeedIcon from './icons/24StrokeFeedIcon';
import StrokeLinkIcon from './icons/24StrokeLinkIcon';
import StrokeUploadIcon from './icons/24StrokeUploadIcon';
import AndroidIcon from './icons/AndroidIcon';
import AppleIcon from './icons/AppleIcon';
import EmailsIcon from './icons/EmailsIcon';
import TwitterIcon from './icons/SolidTwitterIcon';
import styles from './ImportPage.module.css';
import StandardPage from './StandardPage';
import standardPageStyles from './StandardPage.module.css';
import { UpdateImportEmailAddress } from './UpdateImportEmailAddress';

enum SectionType {
  Library = 'library',
  Feed = 'feed',
  Resources = 'resources',
}

const SectionDisplayName = {
  [SectionType.Library]: 'Add to Library',
  [SectionType.Feed]: 'Add to Feed',
  [SectionType.Resources]: 'Resources',
};

const NewsletterIcon = ({ className = '' }: {className?: string;}) => {
  const isDarkMode = globalState(useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []));
  if (isDarkMode) {
    return <NewsletterIconDarkMode className={className} />;
  }

  return <NewsletterIconLightMode className={className} />;
};

export const ImportPage = () => {
  const history = useHistory();
  const { search, hash } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const rssUrlQs = query.get('rss');
  const clientStateLoaded = globalState(useCallback((state) => state.clientStateLoaded, []));
  const isDarkMode = globalState(useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []));
  const [currentSection, setCurrentSection] = useState('integrations');
  const shortcutsMap = useShortcutsMap();

  useHotKeysPreventDefault(
    shortcutsMap[ShortcutId.Esc],
    useCallback(() => {
      history.push('/');
    }, [history]),
    {
      description: 'Close import page',
    },
  );

  useEffect(() => {
    const observerOptions = {
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }

        setCurrentSection(entry.target.id);
      });
    }, observerOptions);

    const sectionElements = Object.values(SectionType).map((id) => document.getElementById(id));

    Array.from(sectionElements).reverse().forEach((i) => {
      if (i) {
        observer.observe(i);
      }
    });
  }, []);

  const scrollToSection = useCallback((sectionId: SectionType, behavior: ScrollBehavior = 'smooth') => {
    const sectionEl = document.getElementById(sectionId);
    const paddingTop = 65;
    const contentWrapper = document.querySelector(`.${styles.contentMainWrapper}`);
    if (sectionEl) {
      contentWrapper?.scroll({ top: sectionEl.offsetTop - paddingTop, behavior });
    }
  }, []);

  const onTocClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const sectionId = (e.target as HTMLAnchorElement).href.split('#')[1];
    scrollToSection(sectionId as SectionType);
  };

  useEffect(() => {
    if (!hash) {
      return;
    }

    const sectionId = hash.split('#')[1];
    scrollToSection(sectionId as SectionType, 'auto');
  }, [hash, scrollToSection]);

  useEffect(() => {
    if (!rssUrlQs || !clientStateLoaded) {
      return;
    }

    const url = decodeURIComponent(rssUrlQs);

    if (!isValidRssUrl(url)) {
      createToast({
        content: 'Please, use a valid URL',
        category: 'error',
      });
      return;
    }

    addFeed({ url }, { userInteraction: 'unknown' });

    history.push({
      pathname: '/feed',
      search: '?shouldSelectLatestFeedDoc=true',
    });
  }, [rssUrlQs, history, clientStateLoaded]);

  return (
    <StandardPage>
      <div className={`${standardPageStyles.standardPageWrapper} ${styles.importWrapper} ${isDarkMode ? styles.isDarkMode : ''}`}>
        <div className={standardPageStyles.header}>
          <h1 className={standardPageStyles.title}>Import Documents</h1>
        </div>

        <div className={`${standardPageStyles.contentMainWrapper} ${styles.contentMainWrapper} has-visible-scrollbar`}>
          <div className={standardPageStyles.contentWrapper}>
            <aside>
              {Object.values(SectionType).map((id) =>
                <a key={id} href={`#${id}`} onClick={onTocClick} className={currentSection === id ? standardPageStyles.isActive : ''}>
                  {SectionDisplayName[id]}
                </a>)}
            </aside>

            <div className={styles.content}>
              <AddToLibrary />
              <AddToFeed />
              <Resources />
            </div>
          </div>
        </div>
      </div>
    </StandardPage>
  );
};

const Section = ({ id, title, subtitle, children }: {id: string; title: string; subtitle?: string; children: React.ReactNode;}) => {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.titleWrapper}>
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </div>
      {children}
    </section>
  );
};

const ListItem = ({ image, title, subtitle, isSmall, children }: { image: React.ReactElement; title: string; subtitle: string | React.ReactNode; isSmall?: boolean; children: React.ReactNode; }) => {
  return <div className={`${styles.listItem} ${isSmall ? styles.isSmall : ''}`}>
    <div className={styles.left}>
      <div className={styles.imageWrapper}>
        {image}
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.title}>
          <h1>{title}</h1>
        </div>
        <div className={styles.subtitle}>
          {subtitle}
        </div>
      </div>
    </div>

    <div className={styles.right}>
      {children}
    </div>
  </div>;
};

const Resources = () => {
  const currentBrowser = getCurrentBrowser();

  const sendAppEmail = async (platform: 'ios' | 'android') => {
    await queueJob({
      jobType: JobType.GenerateEmailForAppInstall,
      jobArguments: { forceSend: true, platform },
      options: { userInteraction: 'click' },
    });
    createToast({ content: `Installation email sent`, category: 'success' });
  };

  const openQrModal = (platform: 'ios' | 'android') => {
    if (platform === 'ios') {
      showModal({ id: 'ios-store-qr' }, { userInteraction: 'unknown' });
    } else {
      showModal({ id: 'android-store-qr' }, { userInteraction: 'unknown' });
    }
  };

  return (
    <Section id={SectionType.Resources} title="Resources">
      <div className={styles.box}>
        <h2 className={styles.boxTitle}>Readwise browser extension</h2>
        <p className={styles.boxSubtitle}>The browser extension allows you to save web pages to Reader, as well as (optionally) highlight and take notes on the open web. Annotations sync bi-directionally between Reader and the web and are then pushed to other connected services like Notion, Obsidian, Logseq, Roam Research, or Evernote (if connected in Readwise).</p>
        <div className={styles.extensionLinks}>
          <a
            className={`${styles.extensionLink} ${currentBrowser === BrowserType.Chrome ? '' : styles.disabled}`}
            href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.extensionLogo} alt="chrome extension logo" src="/onboarding/chrome.svg" />
            <Button className={styles.extensionInstallButton} variant="secondary">Install</Button>
          </a>
          <a
            className={`${styles.extensionLink} ${currentBrowser === BrowserType.Firefox ? '' : styles.disabled}`}
            href="https://addons.mozilla.org/en-US/firefox/addon/readwise-highlighter"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.extensionLogo} alt="firefox extension logo" src="/onboarding/firefox.svg" />
            <Button className={styles.extensionInstallButton} variant="secondary">Install</Button>
          </a>
          <a
            className={`${styles.extensionLink} ${currentBrowser === BrowserType.Safari ? '' : styles.disabled}`}
            href="https://apps.apple.com/app/save-to-reader/id1640236961"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.extensionLogo} alt="safari extension logo" src="/onboarding/safari.svg" />
            <Button className={styles.extensionInstallButton} variant="secondary">Install</Button>
          </a>
          <a
            className={`${styles.extensionLink} ${currentBrowser === BrowserType.Brave ? '' : styles.disabled}`}
            href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.extensionLogo} alt="brave extension logo" src="/onboarding/brave.svg" />
            <Button className={styles.extensionInstallButton} variant="secondary">Install</Button>
          </a>
          <a
            className={`${styles.extensionLink} ${currentBrowser === BrowserType.Edge ? '' : styles.disabled}`}
            href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.extensionLogo} alt="edge extension logo" src="/onboarding/edge.svg" />
            <Button className={styles.extensionInstallButton} variant="secondary">Install</Button>
          </a>
        </div>

        <Bookmarklet />
      </div>

      <div className={styles.box}>
        <h2 className={styles.boxTitle}>Mobile apps</h2>
        <div className={styles.mobileApps}>
          <div className={styles.mobileApp}>
            <div className={styles.left}>
              <AndroidIcon className={styles.androidIcon} />
            </div>
            <div className={styles.right}>
              <h3 className={styles.title}>
                Readwise Reader for Android
              </h3>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
              <p>Text yourself a link to the Google Play store or scan the <a onClick={() => openQrModal('android')}>QR Code</a>.</p>
              <Button variant="secondary" onClick={() => sendAppEmail('android')}>Email link</Button>
            </div>
          </div>
          <div className={styles.mobileApp}>
            <div className={styles.left}>
              <AppleIcon className={styles.appleIcon} />
            </div>
            <div className={styles.right}>
              <h3 className={styles.title}>
                Readwise Reader for iOS
              </h3>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
              <p>Text yourself a link to the Apple App store or scan the <a onClick={() => openQrModal('ios')}>QR Code</a>.</p>
              <Button variant="secondary" onClick={() => sendAppEmail('ios')}>Email link</Button>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.box} ${styles.integrationsBox}`}>
        <div className={styles.integrationsDetails}>
          <h2 className={styles.boxTitle}>Integrations</h2>
          <p>Manage your connections to external services.</p>
        </div>

        <div className={styles.integrationsLink}>
          <Button to="/integrations" variant="secondary">Go to integrations</Button>
        </div>
      </div>

      <div className={`${styles.box} ${styles.integrationsBox}`}>
        <div className={styles.integrationsDetails}>
          <h2 className={styles.boxTitle}>Public API</h2>
          <p>At this time, the Reader API is limited to the capability of saving new documents to the Reader. Additional endpoints will be added in the future.</p>
        </div>

        <div className={styles.integrationsLink}>
          <Button to="https://readwise.io/reader_api" variant="secondary" isExternal target="_blank">API documentation</Button>
        </div>
      </div>
    </Section>
  );
};

const Bookmarklet = () => {
  const [showInstructions, setShowInstructions] = useState(false);

  const bookmarkletContent = `javascript:(function(){
    open(
      '${getServerBaseUrl()}/save?title=' + encodeURIComponent(document.title)
      + '&url=' + encodeURIComponent(location.href)
    )
  })()`.replace(/\s+/g, '');

  const copyBookmarkletCode = () => {
    copyTextOnWeb(bookmarkletContent);
  };

  return (
    <div className={styles.list}>
      <div className={`${styles.listItem} ${styles.bookmarkletWrapper}`}>
        <div className={styles.bookmarkletInstructions}>
          <div className={styles.left}>
            <div className={styles.imageWrapper}>
            <BookmarkletIcon />
            </div>
            <div className={styles.textWrapper}>
              <div className={styles.title}>
                <h1>Bookmarklet</h1>
              </div>
              <div className={styles.subtitle}>
                Add web documents to Reader with a special bookmark. This method does not support highlighting or note taking on the open web.
              </div>
            </div>
          </div>

          <div className={styles.right}>
            <Button
              className={styles.toggleInstructions}
              variant="unstyled"
              onClick={(e: Event) => {
                e.preventDefault();
                setShowInstructions(!showInstructions);
              }}
            >
              {showInstructions ? 'Hide instructions' : 'Show instructions'}
              <ChevronDownIcon className={`${styles.chevronDownIcon} ${showInstructions ? styles.rotate : ''}`} />
            </Button>
          </div>
        </div>

        {showInstructions &&
          <div className={styles.instructionsWrapper}>
            <div className={styles.installing}>
              <div className={styles.smallBorder} />
              <h1>Installing</h1>
              <p>Drag and drop the link below to your bookmarks folder.</p>
              <Button to={bookmarkletContent} variant="primary">Add to Reader</Button>
              <div className={styles.smallBorder} />
            </div>
            <div>
              <p>Or, to install manually, create a new bookmark and paste the following into the URL field</p>
              <div className={styles.inputWithButton} >
                <input onClick={copyBookmarkletCode} aria-labelledby="bookmarklet code" readOnly value={bookmarkletContent} autoComplete="off" />
                <Button variant="secondary" onClick={copyBookmarkletCode}>Copy</Button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

const AddToLibrary = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const { openFileDialog } = useContext(FileUploadContext);
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const libraryEmail = profile?.custom_library_email;
  const [updateEmailDialogIsOpen, setUpdateEmailDialogIsOpen] = useState(false);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!url) {
      return;
    }

    setIsLoading(true);
    await saveNewDocument({ source: 'Reader add from import URL', url }, { userInteraction: 'unknown' });
    background.pollLatestStateAndContent(20);
    history.push('/library');
  };

  const buttonValue = isLoading ? 'Loading...' : 'Add';

  const copyLibraryEmail = useCallback(() => {
    if (libraryEmail) {
      copyTextOnWeb(libraryEmail);
    }
  }, [libraryEmail]);

  return (
    <Section id={SectionType.Library} title="Add to Library" subtitle="Library is where documents you manually save for yourself live">
      <div className={styles.list}>
        <ListItem
          image={<StrokeLinkIcon className={styles.primaryIcon} />}
          title="Add from URL"
          subtitle="Add a document directly from a URL"
          isSmall
        >
          <div className={styles.inputWithButton}>
            <form onSubmit={onSubmit}>
              <input required aria-labelledby="add from url" onChange={(e) => setUrl(e.target.value)} type="url" autoComplete="off" placeholder="http://" />
              <Button variant="secondary" type="submit">{buttonValue}</Button>
            </form>
          </div>
        </ListItem>
        <ListItem
          image={<StrokeUploadIcon className={styles.primaryIcon} />}
          title="Upload file"
          subtitle=".epub, .pdf, .opml, .xml, .csv supported. 500MB max."
          isSmall
        >
          <div className={styles.uploadFile}>
            <Button variant="secondary" onClick={openFileDialog}>Browse files</Button>
            <p>Or drop a file anywhere on top of Reader</p>
          </div>
        </ListItem>
        <ListItem
          image={<EmailsIcon className={styles.emailIcon} />}
          title="Forward email"
          subtitle="To import any email to your Inbox, forward to:"
          isSmall
        >
          <div className={styles.libraryEmail}>
            <div className={styles.inputWithButton} >
              <input onClick={copyLibraryEmail} aria-labelledby="forward email" type="email" readOnly value={libraryEmail} autoComplete="off" />
              <Button variant="secondary" onClick={copyLibraryEmail}>Copy</Button>
            </div>
              <Button className={styles.suggestedFeedsButton} onClick={() => setUpdateEmailDialogIsOpen(true)}>Personalize email addresses</Button>
          </div>
        </ListItem>
        {updateEmailDialogIsOpen && <div>
            <UpdateImportEmailAddress setUpdateEmailDialogIsOpen={setUpdateEmailDialogIsOpen} />
        </div>}
        <ListItem
          image={<TwitterIcon className={styles.twitterLogo} />}
          title="Add from Twitter"
          subtitle="You can save both threads and individual tweets"
          isSmall
        >
          <div className={styles.addFromTwitter}>
            <p>Save a Twitter thread</p>
            <ul>
              <li>Publicly reply with “<b>@readwise save thread</b>“</li>
              <li>Silently direct message <b>@readwise</b> the word “<b>save thread</b>”</li>
            </ul>
          </div>
        </ListItem>
      </div>
    </Section>
  );
};

const AddToFeed = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const { openFileDialog } = useContext(FileUploadContext);
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const feedEmail = profile?.custom_feed_email;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!url) {
      return;
    }

    if (!isValidRssUrl(url)) {
      createToast({
        content: `Please, use a valid URL`,
        category: 'error',
      });
      return;
    }

    setIsLoading(true);
    addFeed({ url }, { userInteraction: 'unknown' });
    history.push({
      pathname: '/feed',
      search: '?shouldSelectLatestFeedDoc=true',
    });
  };

  const buttonValue = isLoading ? 'Loading...' : 'Subscribe';

  const copyEmail = useCallback(() => {
    if (feedEmail) {
      copyTextOnWeb(feedEmail);
    }
  }, [feedEmail]);

  return (
    <Section id={SectionType.Feed} title={SectionDisplayName.feed} subtitle="Feed is where documents that are automatically pushed to you live ">
      <div className={styles.list}>
        <ListItem
          image={<FeedIcon className={styles.feedIcon} />}
          title="Add RSS subscription"
          subtitle={<span>View a list of <Link to="/feed/sources">RSS feeds</Link> or <Link to="/feed/suggestions">suggested feeds</Link>.</span>}
          isSmall
        >
          <div className={styles.inputWithButton}>
            <form onSubmit={onSubmit}>
              <input required aria-labelledby="add from url" onChange={(e) => setUrl(e.target.value)} type="url" autoComplete="off" placeholder="http://" />
              <Button variant="secondary" type="submit">{buttonValue}</Button>
            </form>
          </div>
        </ListItem>
        <ListItem
          image={<StrokeUploadIcon className={styles.primaryIcon} />}
          title="Upload OPML file"
          subtitle=".opml and .xml supported."
          isSmall
        >
          <div className={styles.uploadFile}>
            <Button variant="secondary" onClick={openFileDialog}>Browse files</Button>
            <p>Or drop a file anywhere on top of Reader</p>
          </div>
        </ListItem>
        <ListItem
          image={<NewsletterIcon className={styles.newsletterIcon} />}
          title="Subscribe to email newsletters"
          subtitle="To skip your email inbox, directly subscribe to newsletters using your custom email address:"
          isSmall
        >
          <div className={styles.inputWithButton} >
            <input onClick={copyEmail} aria-labelledby="forward email" type="email" readOnly value={feedEmail} autoComplete="off" />
            <Button variant="secondary" onClick={copyEmail}>Copy</Button>
          </div>
        </ListItem>
        <ListItem
          image={<NewsletterIcon className={styles.newsletterIcon} />}
          title="Autoforward email newsletters"
          subtitle="To set up an autoforward to Feed, forward to:"
          isSmall
        >
          <div className={styles.inputWithButton} >
            <input onClick={copyEmail} aria-labelledby="forward email" type="email" readOnly value={feedEmail} autoComplete="off" />
            <Button variant="secondary" onClick={copyEmail}>Copy</Button>
          </div>
        </ListItem>
        <ListItem
          image={<TwitterIcon className={styles.twitterLogo} />}
          title="Subscribe to public Twitter list"
          subtitle="Delivered twice daily: AM and PM."
          isSmall
        >
          <div className={styles.inputWithButton}>
            <form onSubmit={onSubmit}>
              <input required aria-labelledby="add from url" onChange={(e) => setUrl(e.target.value)} type="url" autoComplete="off" placeholder="http://" />
              <Button variant="secondary" type="submit">{buttonValue}</Button>
            </form>
          </div>
        </ListItem>
      </div>
    </Section>
  );
};
